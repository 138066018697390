<template>
  <div class="historical-report-table" :style="`height: ${height}`">
    <fluency-loader v-if="loading"></fluency-loader>
    <fluent-table v-else
                  :items="reports"
                  :fields="fields"
                  :table-toolbar="bulkOptions"
                  :fixed-header="false"
                  :use-flex-classes="true"
                  :fixed="true"
                  :stickyHeader="true"
                  plan-type="historicalReports"
                  primaryKey="reportReceiptId"
                  sort-by="dateSent"
                  sort-icon-left
                  width-style="calc(100% - 40px);">

      <template #cell(reportName)="row">
        {{row.value}}
        <div class="table-action-container absolute-center-y" style="right: 4px;">
          <t-button-inline severity="secondary" size="small" @click="downloadReport(row.item)">
            <fluency-icon type="download" />
          </t-button-inline>
        </div>
      </template>
    </fluent-table>
  </div>
</template>
<script>
import { downloadFile } from 'core-ui/utils/downloadFile'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'

export default {
  name: 'HistoricReportTable',
  components: { FluencyLoader },
  props: {
    accountPlanId: {
      type: Number
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      reports: undefined,
      loading: false,
      fields: [
        'selectRow',
        {
          key: 'reportName',
          filter: true,
          filterOptions: ['string'],
          defaultWidth: 'auto',
          tdClass: 'position-relative'
        },
        {
          key: 'recipients',
          filter: true,
          filterOptions: ['string'],
          defaultWidth: 'auto'
        },
        {
          key: 'dateSent',
          formatter: this.$moment,
          filter: true,
          filterOptions: ['string'],
          filterByFormatted: true,
          defaultWidth: 200
        },
        {
          key: 'format',
          defaultWidth: 200,
          filter: true,
          filterOptions: [['PDF', 'PPT']]
        },
        {
          key: 'scope',
          formatter: this.convertFromCapsUnderscore,
          defaultWidth: 200,
          filter: true,
          filterOptions: [['CUSTOMER', 'PORTFOLIO', 'ACCOUNT']]
        }
      ],
      bulkOptions: [
        {
          label: 'Download',
          type: 'link',
          action: this.bulkDownloadReports
        }
      ]
    }
  },
  watch: {
    accountPlanId: {
      immediate: true,
      handler () {
        this.fetchReports()
      }
    }
  },
  computed: {
    selectedRows () {
      return this.$store.getters['table/selectedRowObjs']('historicalReports') || []
    }
  },
  methods: {
    async fetchReports () {
      this.loading = true
      const resp = await this.$res.reporting.listHistoric(this.accountPlanId)
      if (resp) {
        this.reports = resp
      }
      this.loading = false
    },
    moment (timestamp) {
      return this.$moment.utc(timestamp).local().format('MM/DD/YY, h:mm a')
    },
    convertFromCapsUnderscore (text) {
      return this.$filters.convertFromCapsUnderscore(text)
    },
    async downloadReport (report) {
      const resp = await this.$res.reporting.fetchHistoric(report)
      if (resp) {
        const fileName = resp.headers['x-filename']
        downloadFile(resp.data, undefined, fileName)
      }
    },
    async bulkDownloadReports () {
      const resp = await this.$res.reporting.bulkFetchHistoric(this.selectedRows)
      if (resp) {
        downloadFile(resp.data, 'application/zip', 'Reporting History Bulk Download')
      }
    }
  }
}
</script>
